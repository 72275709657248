* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	padding: 0 !important;
}

body > iframe {
	pointer-events: none;
}

*:not(input, textarea) {
	user-select: none;
}

/* importing montserrat font */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap");

/* This CSS for studentnav component ------------ start */

@media (max-width: 991px) {
	.navbar-header {
		float: none;
	}

	.navbar-left,
	.navbar-right {
		float: none !important;
	}

	.navbar-toggle {
		display: block;
		margin-right: 0 !important;
	}

	.navbar-collapse {
		border-top: 1px solid transparent;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
	}

	.navbar-fixed-top {
		top: 0;
		border-width: 0 0 1px;
	}

	.navbar-collapse.collapse {
		display: none !important;
	}

	.navbar-nav {
		float: none !important;
		margin-top: 7.5px;
	}

	.navbar-nav > li {
		float: none;
	}

	.navbar-nav > li > a {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.collapse.in {
		display: block !important;
	}
}

/* This CSS for studentnav component ------------ End */

.react-calendar__tile--now-highlighted {
	background:#012c63 !important;
	color: #fff !important;
}